import { FC } from 'react';
import { formatDate } from 'utilities';
import { formatDistance } from 'date-fns/formatDistance';
import { IEmployerProps } from 'interfaces';

let presentStr = '';
const getDate = (dataStr: string, present?: boolean): string => (present ? presentStr : formatDate(dataStr) || '');

export const Employer: FC<IEmployerProps> = ({
	additional,
	additionalText,
	content,
	end_date,
	location,
	position,
	presentText,
	start_date,
	still_employed,
	title,
}: IEmployerProps): JSX.Element => {
	presentStr = presentText;
	const startDate = getDate(start_date);
	const endDate = getDate(end_date, still_employed);
	const actualEnd = end_date.length ? new Date(end_date) : new Date();
	const dateSpan = formatDistance(new Date(start_date), actualEnd);

	return (
		<div className='employer' itemProp='employee' itemScope={true} itemType='http://schema.org/EmployeeRole'>
			<div className='jobDates'>
				<span className='jobDateSpan' data-testid='jobDateSpan'>
					{dateSpan}
				</span>
				<span className='jobStart' data-actualdate={start_date} data-testid='jobStart' itemProp='startDate'>
					{startDate}
				</span>
				<span className='jobEnd' data-actualdate={end_date} data-testid='jobEnd' itemProp='endDate'>
					{endDate}
				</span>
			</div>
			<div className='jobPosition' data-testid='jobPosition' itemProp='roleName'>
				{position}
			</div>
			<div
				className='jobEmployer'
				data-testid='jobEmployer'
				itemProp='name'
				itemScope={true}
				itemType='http://schema.org/Organization'
			>
				{title.rendered}
			</div>
			<div
				className='jobAddress'
				data-testid='jobAddress'
				itemProp='address'
				itemScope={true}
				itemType='http://schema.org/Organization'
			>
				{location}
			</div>
			<div className='jobDescription' itemProp='description' />
			<div dangerouslySetInnerHTML={{ __html: content.rendered }} data-testid='jobDescription' />
			{additional.length > 0 && (
				<div className='jobAddl' data-testid='jobAddl' itemProp='alternateName'>
					{additionalText} {additional}
				</div>
			)}
		</div>
	);
};
