import { Contact } from 'components';
import { DataContext } from 'App';
import { FC, ReactNode } from 'react';

export const ContactContainer: FC = () => (
	<DataContext.Consumer>
		{(dataContext): ReactNode => {
			const compProps = { textKeys: dataContext.textKeys };

			return <Contact {...dataContext.pages.contact} {...compProps} />;
		}}
	</DataContext.Consumer>
);
