import { DataContext } from 'App';
import { FC, ReactNode } from 'react';
import { Resume } from 'components';

export const ResumeContainer: FC = () => (
	<DataContext.Consumer>
		{({ education, references, textKeys, work, pages: { resume } }): ReactNode => {
			const compProps = {
				education,
				references,
				textKeys,
				work,
			};

			return <Resume {...resume} {...compProps} />;
		}}
	</DataContext.Consumer>
);
