import { FC } from 'react';

interface IFooterProps {
	footerText: string;
}

export const Footer: FC<IFooterProps> = ({ footerText }): JSX.Element => (
	<footer className='footer' itemScope={true} itemType='http://schema.org/WPFooter'>
		<div className='container'>
			<div className='content has-text-centered'>
				<p data-testid='footer-text'>{footerText}</p>
			</div>
		</div>
	</footer>
);
