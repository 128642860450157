import { FC } from 'react';
import { humanizeDate } from 'utilities';
import { IReferenceData } from 'interfaces';

export const Reference: FC<IReferenceData> = ({ content, date_provided, job_title, title }): JSX.Element => (
	<div className='reference' itemProp='review' itemScope={true} itemType='http://schema.org/CreativeWork'>
		<div
			className='refDetails'
			dangerouslySetInnerHTML={{ __html: content.rendered }}
			data-testid='refDetails'
			itemProp='reviewBody'
		/>
		<div className='refAuthor' data-testid='refAuthor' itemProp='author'>
			{title.rendered}
		</div>
		<div className='refTitle' data-testid='refTitle' itemProp='reviewAspect'>
			{job_title}
		</div>
		<div className='refDate' data-actualdate={date_provided} data-testid='refDate' itemProp='dateCreated'>
			{humanizeDate(date_provided)}
		</div>
	</div>
);
