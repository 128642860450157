import { FC } from 'react';
import { ISocialAccountData } from 'interfaces';
import { SocialItem } from '.';
export interface ISocialProps {
	socialItems: ISocialAccountData[];
}

export const Social: FC<ISocialProps> = ({ socialItems }: ISocialProps): JSX.Element => (
	<div>
		<div className='showView' id='socialLinks'>
			<ul className='social-networks square'>
				{socialItems.map((link: ISocialAccountData) => (
					<SocialItem key={link.id} {...link} />
				))}
			</ul>
		</div>
	</div>
);
