import { Context, createContext, FC, useEffect, useRef, useState } from 'react';
import { getData, getEducation, getEmployers, getPages, getReferences, getSocial } from 'utilities';
import { MainContainer } from 'pages';
import { Navbar, PageLoader } from 'components';
import { TEXT_KEYS } from 'data';
import {
	IAnimationObj,
	IEducationData,
	IEmployerData,
	IPage,
	IPageData,
	IReferenceData,
	IServerData,
	ISocialAccountData,
	ITextKeys,
} from 'interfaces';

// startLogger();

export interface IAppData extends Omit<IServerData, 'pageItems'> {
	pages: IPage;
	textKeys: ITextKeys;
}

export const DataContext: Context<IAppData> = createContext({} as IAppData);

export const App: FC = (): JSX.Element => {
	const loadingID: string = 'loadingLogo';
	const [isLoading, setIsLoading] = useState(true);
	const [loaderAnimating, setLoaderAnimating] = useState(true);
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const textKeys = useRef<ITextKeys>(TEXT_KEYS);
	const pages = useRef<IPage>({});
	const pageData = useRef<{
		education: IEducationData[];
		references: IReferenceData[];
		social: ISocialAccountData[];
		work: IEmployerData[];
	}>({
		education: [],
		references: [],
		social: [],
		work: [],
	});

	const processData = (serverData: IServerData): void => {
		const tempPages: IPage = {};
		const { pageItems, ...other }: IServerData = serverData;

		pageItems.forEach((page: IPageData): void => {
			tempPages[page.slug] = page;
		});
		setIsLoading(false);
		pages.current = tempPages;
		pageData.current = { ...other };
	};
	const whichAnimationEvent = (): string => {
		const el: HTMLElement | null = document.getElementById(loadingID);
		const animations: IAnimationObj = {
			animation: 'animationend',
			MozAnimation: 'animationend',
			OAnimation: 'oAnimationEnd',
			WebkitAnimation: 'webkitAnimationEnd',
		};

		if (el) {
			type AnimType = (typeof animations)[keyof typeof animations];
			for (const t in animations) {
				if (el.style[t] !== undefined) {
					return animations[t] as AnimType;
				}
			}
		}

		return '';
	};

	// check to see if logo is done animating so it doesn't cut off
	const el: HTMLElement | null = document.getElementById(loadingID);
	const animationEvent: string = whichAnimationEvent();
	const handleLoadingAnimation = () => setLoaderAnimating(false);

	if (el) {
		el.addEventListener(animationEvent, handleLoadingAnimation);
	}

	const setMobileMenu = (menuOpen: boolean): void => {
		setMobileMenuOpen(menuOpen);
	};

	useEffect(() => {
		if (!loaderAnimating) {
			console.log('onAnimationEnd called');
			el?.removeEventListener(animationEvent, handleLoadingAnimation);
		}
	}, [loaderAnimating, el, animationEvent]);

	useEffect(() => {
		const getAllData = () => {
			const apiCalls: [
				Promise<IPageData[]>,
				Promise<ISocialAccountData[]>,
				Promise<IEmployerData[]>,
				Promise<IEducationData[]>,
				Promise<IReferenceData[]>,
			] = [getData(getPages), getData(getSocial), getData(getEmployers), getData(getEducation), getData(getReferences)];

			try {
				Promise.all(apiCalls).then(
					([pageItems, social, work, education, references]: [
						IPageData[],
						ISocialAccountData[],
						IEmployerData[],
						IEducationData[],
						IReferenceData[],
					]) => {
						const serverData: IServerData = {
							education: education,
							pageItems: pageItems,
							references: references,
							social: social,
							work: work,
						};

						processData(serverData);
					},
				);
			} catch (err) {
				console.log(err);
			}
		};

		getAllData();
	}, []);

	return (
		<div id='appMain' itemScope={true} itemType='http://schema.org/WebPage'>
			{isLoading || loaderAnimating ? (
				<PageLoader loadingID={loadingID} />
			) : (
				<DataContext.Provider value={{ textKeys: textKeys.current, pages: pages.current, ...pageData.current }}>
					<Navbar setMenuState={setMobileMenu} />
					<MainContainer data-testid='main' mobileNav={mobileMenuOpen} />
				</DataContext.Provider>
			)}
		</div>
	);
};
