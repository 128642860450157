import classnames from 'classnames';
import React, { FC, FormEvent, MouseEvent, useState } from 'react';
import { IContactFields, IPageData, ITextKeys } from 'interfaces';
import { TEXT_KEYS } from 'data';

type IContactProps = IPageData;

export const submitFormData = (formData) => {
	fetch('https://usebasin.com/f/60d54aefd589', {
		body: JSON.stringify(formData),
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		},
		method: 'POST',
	}).then((response) => {
		console.log(`Successful ${response.body}`);
	});
};

export const Contact: FC<IContactProps> = (props) => {
	const textKeys: ITextKeys = TEXT_KEYS;
	const [{ contactEmail, contactMsg, contactName }, setFormData] = useState({
		contactEmail: '',
		contactMsg: '',
		contactName: '',
	});
	const [formSubmitted, setFormSubmitted] = useState(false);
	const [isFormFilled, setIsFormFilled] = useState(false);

	const handleChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
		const target: HTMLInputElement | HTMLTextAreaElement = evt.target;
		const value: string = target.value;
		const name: string = target.name;

		setIsFormFilled(Boolean(contactEmail.length && contactMsg.length && contactName.length));

		setFormData((prevState) => ({ ...prevState, [name]: value }));
	};

	const submit = (formData: IContactFields): void => {
		console.log('SUBMITTING FORM', formData, JSON.stringify(formData));
		submitFormData(formData);
	};
	const resetForm = (evt: MouseEvent<HTMLButtonElement>): void => {
		evt.preventDefault();
		setFormData({
			contactEmail: '',
			contactMsg: '',
			contactName: '',
		});
		setIsFormFilled(false);
	};
	const validateBeforeSubmit = (evt: FormEvent): void => {
		evt.preventDefault();
		const formData: IContactFields = {
			contactEmail,
			contactMsg,
			contactName,
		};

		if (contactEmail.length && contactMsg.length && contactName.length) {
			setFormSubmitted(true);
			submit(formData);
		}

		console.log('VALIDATING FORM');
	};

	return (
		<div className='container' id='contact-form' itemScope={true} itemType='http://schema.org/ContactPage'>
			<div
				className='contact-buzzoff'
				dangerouslySetInnerHTML={{ __html: props.content.rendered }}
				data-testid='contact-page-content'
				id='contact-content'
			/>
			{!formSubmitted ? (
				<form className={formSubmitted ? 'submitted' : 'thankyou'} id='contact-dg-form' onSubmit={validateBeforeSubmit}>
					<div className='field'>
						<label className='label' data-testid={'contact-name-label'}>
							{textKeys.contact.name}
							<span className='required' />
						</label>
						<div className='control'>
							<input
								className={classnames('input')}
								data-testid={'contact-name-field'}
								id='contactName'
								name='contactName'
								placeholder={textKeys.contact.nameDefault}
								required={true}
								type='text'
								value={contactName}
								onChange={handleChange}
							/>
						</div>
					</div>
					<div className='field'>
						<label className='label' data-testid={'contact-email-label'}>
							{textKeys.contact.email}
							<span className='required' />
						</label>
						<div className='control has-icons-left has-icons-right'>
							<input
								className={classnames('input')}
								data-testid={'contact-email-field'}
								id='contactEmail'
								name='contactEmail'
								placeholder={textKeys.contact.emailDefault}
								required={true}
								type='email'
								value={contactEmail}
								onChange={handleChange}
							/>
							<span className='icon is-small is-left'>
								<i className='fas fa-envelope' />
							</span>
						</div>
					</div>
					<div className='field'>
						<label className='label' data-testid={'contact-msg-label'}>
							{textKeys.contact.message}
							<span className='required' />
						</label>
						<div className='control'>
							<textarea
								className={classnames('textarea')}
								data-testid={'contact-msg-field'}
								id='contactMsg'
								name='contactMsg'
								placeholder={textKeys.contact.messageDefault}
								required={true}
								value={contactMsg}
								onChange={handleChange}
							/>
						</div>
					</div>
					<div className='field is-grouped'>
						<div className='control'>
							<button className='button is-link' data-testid={'submit-btn'} disabled={!isFormFilled}>
								{textKeys.buttons.submit}
							</button>
						</div>
						<div className='control'>
							<button className='button is-text' data-testid={'cancel-btn'} onClick={resetForm}>
								{textKeys.buttons.cancel}
							</button>
						</div>
					</div>
				</form>
			) : (
				<div className='thankyou' data-testid={'thank-you'}>
					<h2>{textKeys.contact.thankYou}</h2>
				</div>
			)}
		</div>
	);
};
