import { FC } from 'react';
import { IPageData } from 'interfaces';

export const About: FC<IPageData> = ({ title, content }: IPageData): JSX.Element => (
	<div className='container' id='about' itemScope={true} itemType='http://schema.org/QAPage'>
		<div className='box'>
			<h2 className='title' data-testid='about-page-title'>
				{title.rendered}
			</h2>
			<div dangerouslySetInnerHTML={{ __html: content.rendered }} data-testid='about-page-content' id='aboutcontent' />
		</div>
	</div>
);
