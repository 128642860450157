import { format, formatDistanceToNow } from 'date-fns';

export const cleanWPdate = (dateStr: string, yearLast: boolean = false): Date => {
	let pattern: RegExp = /(\d{4})(\d{2})(\d{2})/;
	let cleanedDate;

	if (yearLast) {
		pattern = /(\d{2})(\d{2})(\d{4})/;
		cleanedDate = new Date(dateStr.replace(pattern, '$3-$1-$2'));
	} else {
		cleanedDate = new Date(dateStr.replace(pattern, '$1-$2-$3'));
	}

	// eslint-disable-next-line @typescript-eslint/no-magic-numbers, no-mixed-operators
	return new Date(cleanedDate.valueOf() + cleanedDate.getTimezoneOffset() * 60 * 1000);
};
export const humanizeDate = (value: string): string | undefined => {
	let retVal: string | undefined;

	if (value.length) {
		const cleanDate: Date = cleanWPdate(value, true);

		retVal = formatDistanceToNow(new Date(cleanDate), { addSuffix: true });
	}
	return retVal;
};
export const formatDate = (value: string, clean?: boolean): string | undefined => {
	let retVal: string | undefined;

	if (value.length) {
		const cleanDate: Date = cleanWPdate(value, clean);

		retVal = format(new Date(cleanDate), 'MM/dd/yyyy');
	}
	return retVal;
};
