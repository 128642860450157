import { AboutContainer, ContactContainer, HomeContainer, ResumeContainer } from 'pages';
import { FC } from 'react';
import { motion } from 'framer-motion';
import { Outlet, Route, Routes, useLocation } from 'react-router-dom';

const PageLayout = ({ children }) => children;

const pageVariants = {
	'initial': {
		opacity: 0,
	},
	'in': {
		opacity: 1,
	},
	'out': {
		opacity: 0,
	},
};

const pageTransition = {
	type: 'tween',
	ease: 'linear',
	duration: 0.5,
};

const PageContentWrapper = () => {
	const { pathname } = useLocation();

	return (
		<PageLayout>
			<motion.div animate='in' initial='initial' key={pathname} transition={pageTransition} variants={pageVariants}>
				<Outlet />
			</motion.div>
		</PageLayout>
	);
};

export const PageContent: FC = () => (
	<Routes>
		<Route element={<PageContentWrapper />}>
			<Route element={<HomeContainer />} path='/' />
			<Route element={<AboutContainer />} path='/about' />
			<Route element={<ResumeContainer />} path='/resume' />
			<Route element={<ContactContainer />} path='/contact' />
			<Route element={<HomeContainer />} path='*' />
		</Route>
	</Routes>
);
