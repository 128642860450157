import { FC } from 'react';
import { ISocialAccountData } from 'interfaces';

export interface ISocialItemProps extends ISocialAccountData {
	isMobile?: boolean;
}

const getSocialLink = (linkData: ISocialItemProps, classList: string): JSX.Element => (
	<a className={classList} data-testid='social-link' href={linkData.url} rel='noopener noreferrer' target='_blank'>
		{linkData.title.rendered}
	</a>
);

export const SocialItem: FC<ISocialItemProps> = (props: ISocialItemProps): JSX.Element => (
	<>
		{props.isMobile ? (
			getSocialLink(props, `navbar-item nestedLink icon-menu-${props.slug}`)
		) : (
			<li data-dgtip={props.title.rendered}>{getSocialLink(props, `icon icon-${props.slug}`)}</li>
		)}
	</>
);
