import { FC } from 'react';
import { NavLink } from 'react-router-dom';

export interface INavItemProps {
	label: string;
	path: string;
	onClick?: () => void;
}
export const NavItem: FC<INavItemProps> = ({ label, path, onClick }: INavItemProps): JSX.Element => (
	<div className='appNavLink' data-testid='nav-item'>
		<NavLink
			className={({ isActive }) => (isActive ? 'is-active' : '')}
			data-hover={label}
			role='navigation'
			to={path}
			onClick={onClick}
		>
			{label}
		</NavLink>
	</div>
);
